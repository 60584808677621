<template>
  <div class="w100 h100 index">
    <SlideLogin></SlideLogin>
    <!-- <Verify
      @success="success"
      mode="pop"
      captchaType="blockPuzzle"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>
    <button @click="useVerify">调用验证组件</button> -->
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
  methods: {
    success() {},
    useVerify() {
      this.$refs.verify.show();
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.index {
  background: rgba(0, 0, 0, 0.2);
}
</style>
